// extracted by mini-css-extract-plugin
export var awsExpertImg = "OpenSourceMain-module--awsExpertImg--2d7b6";
export var awsExpertImgDiv = "OpenSourceMain-module--awsExpertImgDiv--17ccb";
export var awsMainImage = "OpenSourceMain-module--awsMainImage--2d07f";
export var businessImage = "OpenSourceMain-module--businessImage--87cc8";
export var businessItem = "OpenSourceMain-module--businessItem--c7a05";
export var businessItemImage = "OpenSourceMain-module--businessItemImage--5cde9";
export var businessItemMain = "OpenSourceMain-module--businessItemMain--add65";
export var businessMain = "OpenSourceMain-module--businessMain--6bdb2";
export var businessText = "OpenSourceMain-module--businessText--45322";
export var buttonClasses = "OpenSourceMain-module--buttonClasses--2051c";
export var carouselContainer = "OpenSourceMain-module--carouselContainer--71a06";
export var contactButton = "OpenSourceMain-module--contactButton--b2e40";
export var heroBackgroundCircleLeft = "OpenSourceMain-module--heroBackgroundCircleLeft--2900b";
export var heroBackgroundInnerCircleLeft = "OpenSourceMain-module--heroBackgroundInnerCircleLeft--3ef50";
export var knowUs = "OpenSourceMain-module--knowUs--c98c8";
export var knowUsImage = "OpenSourceMain-module--knowUsImage--ddba8";
export var knowUsSubDiv = "OpenSourceMain-module--knowUsSubDiv--b299a";
export var knowUsText = "OpenSourceMain-module--knowUsText--3fca2";
export var partners = "OpenSourceMain-module--partners--b84fc";
export var partnersBenefits = "OpenSourceMain-module--partnersBenefits--66d48";
export var partnersBenefitsCarousel = "OpenSourceMain-module--partnersBenefitsCarousel--7004f";
export var partnersBenefitsInner = "OpenSourceMain-module--partnersBenefitsInner--c4ffb";
export var partnersBenefitsItem = "OpenSourceMain-module--partnersBenefitsItem--c329b";
export var partnersBenefitsItemBusiness = "OpenSourceMain-module--partnersBenefitsItemBusiness--03f20";
export var partnersBenefitsItemBusinessTop = "OpenSourceMain-module--partnersBenefitsItemBusinessTop--a8251";
export var partnersBenefitsTech = "OpenSourceMain-module--partnersBenefitsTech--162bb";
export var partnersButtonsContainer = "OpenSourceMain-module--partnersButtonsContainer--f7cdc";
export var partnersMainSlider = "OpenSourceMain-module--partnersMainSlider--ff225";