// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "OpenSourceProjectsSlider-module--PartnerMobileCarousel--bee1b";
export var PartnersCarouselDesc = "OpenSourceProjectsSlider-module--PartnersCarouselDesc--ea43b";
export var blogArrowContainer = "OpenSourceProjectsSlider-module--blogArrowContainer--1d1ab";
export var btnDiv = "OpenSourceProjectsSlider-module--btnDiv--6a3ea";
export var carousel = "OpenSourceProjectsSlider-module--carousel--7eb22";
export var carouselDiv = "OpenSourceProjectsSlider-module--carouselDiv--2155f";
export var carouselImage = "OpenSourceProjectsSlider-module--carouselImage--3f5c1";
export var carouselImageDiv = "OpenSourceProjectsSlider-module--carouselImageDiv--9d873";
export var carouselImg = "OpenSourceProjectsSlider-module--carouselImg--e4a2f";
export var carouselImgDiv = "OpenSourceProjectsSlider-module--carouselImgDiv--4d5ed";
export var carouselMainDiv = "OpenSourceProjectsSlider-module--carouselMainDiv--70186";
export var carousel__container = "OpenSourceProjectsSlider-module--carousel__container--ef06b";
export var carousel__slider = "OpenSourceProjectsSlider-module--carousel__slider--023de";
export var carousel__sliderTrayWrapHorizontal = "OpenSourceProjectsSlider-module--carousel__slider-tray-wrap--horizontal--e3258";
export var carousel__sliderTrayWrapper = "OpenSourceProjectsSlider-module--carousel__slider-tray-wrapper--c8877";
export var caseStudiesDescription = "OpenSourceProjectsSlider-module--caseStudiesDescription--bcf54";
export var contactButton = "OpenSourceProjectsSlider-module--contactButton--aacdf";
export var paragraph = "OpenSourceProjectsSlider-module--paragraph--4bf43";
export var partnersMainSlider = "OpenSourceProjectsSlider-module--partnersMainSlider--54226";
export var subDiv = "OpenSourceProjectsSlider-module--subDiv--5484a";